import "floating-vue/dist/style.css";
import "./bootstrap";
import "../css/app.css";

import { createApp, h } from "vue";
import { createPinia } from "pinia";
import { createInertiaApp } from "@inertiajs/vue3";
import { resolvePageComponent } from "laravel-vite-plugin/inertia-helpers";
import { ZiggyVue } from "../../vendor/tightenco/ziggy/dist/vue.m";
import VueGoogleMaps from "@fawmi/vue-google-maps";
import VueScrollTo from "vue-scrollto";
import FloatingVue from "floating-vue";
import * as Sentry from "@sentry/vue";
import * as ConfirmDialog from "vuejs-confirm-dialog";

import Cover1 from "@/Pages/Settings/Branding/Covers/Cover1.vue";
import Cover2 from "@/Pages/Settings/Branding/Covers/Cover2.vue";

createInertiaApp({
  progress: {
    color: "#F56565",
  },
  resolve: (name) =>
    resolvePageComponent(
      `./Pages/${name}.vue`,
      import.meta.glob("./Pages/**/*.vue"),
    ),
  setup({ el, App, props, plugin }) {
    const pinia = createPinia();

    const Vue = createApp({ render: () => h(App, props) })
      .use(plugin)
      .use(pinia)
      .use(ZiggyVue, Ziggy)
      .use(VueScrollTo)
      .use(ConfirmDialog)
      .use(FloatingVue, {
        themes: {
          inspecteasy: {
            $extend: "tooltip",
          },
        },
      })
      .use(VueGoogleMaps, {
        load: {
          v: "weekly",
          loading: "async",
          key: "AIzaSyC_WRUturLjhlACPEPWoFCEDdwehYAy6Gk",
          libraries: "places",
          language: "en",
          countries: ["au", "nz"],
        },
      });

    Vue.component("Cover1", Cover1);
    Vue.component("Cover2", Cover2);

    Vue.directive("focus-on-create", {
      mounted: function (el, binding) {
        if (binding.value) {
          let { index, total } = binding.value;

          if (index + 1 === total && el.querySelector("input")) {
            el.querySelector("input").focus();
          }
        } else {
          el.lastChild.querySelector("input").focus();
        }
      },
    });

    Vue.directive("click-outside", {
      mounted: function (el, binding) {
        el.clickOutsideEvent = function (event) {
          if (!(el == event.target || el.contains(event.target))) {
            binding.value(event, el);
          }
        };
        document.addEventListener("click", el.clickOutsideEvent);
      },
      unmounted: function (el) {
        document.removeEventListener("click", el.clickOutsideEvent);
      },
    });

    /**
     * Sentry error reporting.
     */
    Sentry.init({
      Vue,
      dsn: "https://a7d94029682344aaaeb1c68b3b9ac918@o1173203.ingest.sentry.io/4505428980924416",
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
      enabled: import.meta.env.PROD,

      integrations: [new Sentry.Replay()],
      ignoreErrors: ["Request failed with status code 401"],
    });

    return Vue.mount(el);
  },
});
